<template>
  <div class="selectProducts">
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="客房" name="tag_1">
        <div class="search">
          <span>客房名称:</span>
          <el-input
            v-model="roomListData.roomName"
            placeholder="请输入客房名称"
          ></el-input>
          <span>所属酒店:</span>
          <el-select
            v-model="roomListData.hotelId"
            clearable
            @clear="onClear(1)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hotelList"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="getRoomList()">查找</el-button>
        </div>
        <el-table
          size="mini"
          border
          :data="roomData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            prop="roomId"
            label="客房id"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="roomName"
            label="客房名称"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="hotelName"
            label="所属酒店"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="strSellWay"
            label="销售方式"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="strUnsubscribe"
            label="是否退订"
            width="width"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="onAdd([row], 1)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="roomListData.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="roomListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="roomPagination.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="门票" name="tag_2">
        <div class="search">
          <span>门票名称:</span>
          <el-input
            v-model="ticketListData.ticketName"
            placeholder="请输入门票名称"
          ></el-input>
          <span>所属景区:</span>
          <el-select
            v-model="ticketListData.sceneryId"
            clearable
            @clear="onClear(2)"
            placeholder="请选择"
          >
            <el-option
              v-for="item in sceneryList"
              :key="item.cateId"
              :label="item.cateName"
              :value="item.cateId"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="getTicketList()">查找</el-button>
        </div>
        <el-table
          size="mini"
          border
          :data="ticketDate"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            prop="ticketId"
            label="门票ID"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="ticketName"
            label="门票名称"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sceneryName"
            label="所属景区"
            width="width"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="strSellWay"
            label="销售方式"
            width="width"
          >
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="{ row }">
              <el-button type="primary" size="mini" @click="onAdd([row], 2)"
                >添加</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="ticketListData.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="ticketListData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="ticketPagination.total"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="线路" name="tag_3">
        <div v-if="!taocanListData.travelId">
          <div class="search line">
            <span>商品名称:</span>
            <el-input
              v-model="lineListData.travelName"
              placeholder="请输入商品名称"
            ></el-input>
            <span>所属分类:</span>
            <el-select
              clearable
              @clear="onClear(4)"
              v-model="cateValue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListA"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              @clear="onClear(5)"
              v-show="cateValue"
              v-model="cateValue1"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListB"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-select
              clearable
              @clear="onClear(3)"
              v-show="cateValue1"
              v-model="lineListData.cateId"
              placeholder="请选择"
            >
              <el-option
                v-for="item in cateListC"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="getlineList()">查找</el-button>
          </div>
          <el-table
            size="mini"
            border
            :data="lineDate"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              align="center"
              prop="travelId"
              label="线路ID"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="travelName"
              label="副标题"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="cateName"
              label="所属分类"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="unsubscribe"
              label="是否退订"
              width="width"
            >
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row], 3)"
                  >确认</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange3"
            @current-change="handleCurrentChange3"
            :current-page="lineListData.currentPage"
            :page-sizes="[5, 10, 15, 20]"
            :page-size="lineListData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="linePagination.total"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="美食" name="tag_4" v-if="showFood">
        <foodList
          :status="2"
          @change="
            (row) => {
              this.multipleSelection = row;
            }
          "
          @submit="(row) => onAdd([row], 4)"
        ></foodList>
      </el-tab-pane>
      <el-tab-pane label="超级礼包" name="tag_6" v-if="showGift">
        <giftBagList
          ref="giftBagRef"
          type="select"
          @change="
            (row) => {
              this.multipleSelection = row;
            }
          "
          @submit="(row) => onAdd(row, 6)"
        ></giftBagList>
      </el-tab-pane>
    </el-tabs>

    <div class="pb_footer">
      <el-button @click="$emit('close')">取 消</el-button>
      <el-button type="primary" @click="onAdd(multipleSelection, activeName)"
        >确 定</el-button
      >
    </div>
  </div>
</template>
<script>
import giftBagList from "./seckillInfo/giftBagList.vue";

import {
  checkRoomList,
  checkTicketList,
  checkTravelList,
} from "@/api/wanxiaoShop";
import { allHotel } from "@/api/hotelList";
import { roomList } from "@/api/room";
import { allScenery } from "@/api/sceneryList";
import { ticketList } from "@/api/ticket";
import { selectAllByFatherId } from "@/api/travel";
import { lineList } from "@/api/lineList";
import { travelSpecsList } from "@/api/travelSpecs";
import foodList from "../food/foodList";
export default {
  name: "productBox",
  components: {
    foodList,
    giftBagList,
  },
  props: {
    showFood: {
      type: Boolean,
      default: true,
    },
    showGift: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hotelList: [],
      roomData: [],
      roomListData: {
        currentPage: 1,
        pageSize: 5,
        hotelId: null,
        roomName: "",
        status: 1,
        activityType: 2,
      },
      roomPagination: {},
      sceneryList: [],
      ticketDate: [],
      ticketListData: {
        currentPage: 1,
        pageSize: 5,
        ticketName: "",
        sceneryId: null,
        status: 1,
        activityType: 2,
      },
      ticketPagination: {},
      lineDate: [],
      lineListData: {
        currentPage: 1,
        pageSize: 5,
        travelName: "",
        cateId: null,
        status: 1,
        activityType: 2,
      },
      linePagination: {},
      cateValue: null,
      cateValue1: null,
      cateListA: [],
      cateListB: [],
      cateListC: [],
      taocanPagination: {},
      taocanDate: [],
      taocanListData: {
        currentPage: 1,
        pageSize: 5,
        specsName: "",
        travelId: null,
        status: 1,
      },

      activeName: "tag_1",
      multipleSelection: [], // 多选项
    };
  },
  watch: {
    cateValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue,
      });
      this.cateListB = data.data;
    },
    cateValue1: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: this.cateValue1,
      });
      this.cateListC = data.data;
    },
  },
  created() {
    this.getAllHotel();
    this.getRoomList();
    this.getallScenery();
    this.getTicketList();
    this.getTravelCate();
    this.getlineList();
  },
  methods: {
    onClear(value) {
      if (value == 1) {
        this.roomListData.hotelId = null;
      } else if (value == 2) {
        this.ticketListData.sceneryId = null;
      } else if (value == 3) {
        this.lineListData.cateId = null;
      } else if (value == 4) {
        this.cateValue = null;
        this.cateValue1 = null;
        this.lineListData.cateId = null;
      } else if (value == 5) {
        this.cateValue1 = null;
        this.lineListData.cateId = null;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.activeName, this.multipleSelection);
    },
    async getAllHotel() {
      const { data } = await allHotel();
      this.hotelList = data.data;
    },
    async getRoomList() {
      const { data } = await checkRoomList(this.roomListData);
      this.roomData = data.list;
      this.roomPagination = data.pagination;
    },
    async getallScenery() {
      const { data } = await allScenery();
      this.sceneryList = data.data;
    },
    async getTicketList() {
      const { data } = await checkTicketList(this.ticketListData);
      console.log(data, "qeqw1321");
      this.ticketDate = data.list;
      this.ticketPagination = data.pagination;
    },
    async getTravelCate() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      this.cateListA = data.data;
    },
    async getlineList() {
      const { data } = await checkTravelList(this.lineListData);
      this.lineDate = data.list;
      this.linePagination = data.pagination;
    },
    async gettravelSpecsList() {
      const { data } = await travelSpecsList(this.taocanListData);
      console.log(data, "13213adaw");
      this.taocanDate = data.list;
      this.taocanPagination = data.pagination;
    },
    async onAdd(list, strType) {
      console.log(strType, list);
      let type = this.activeName.split("_")[1];
      if (list.length < 1) {
        return this.$message.error("请先选择产品！");
      }
      if (type == 1) {
        var newList = list.map((item) => {
          return {
            id: item.roomId,
            name: item.roomName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 2) {
        var newList = list.map((item) => {
          return {
            id: item.ticketId,
            name: item.ticketName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 3) {
        var newList = list.map((item) => {
          return {
            id: item.travelId,
            name: item.travelName,
          };
        });
        this.$emit("addData", newList, type);
      } else if (type == 4) {
        var newList = list.map((item) => {
          return {
            id: item.foodId,
            name: item.title,
          };
        });
        console.log(newList, "newList");

        this.$emit("addData", newList, type);
      }else if (type == 6) {
        var newList = list.map((item) => {
          return {
            id: item.packId,
            name: item.title,
          };
        });
        console.log(newList, "newList");

        this.$emit("addData", newList, type);
      }
    },
    onTaocan(id) {
      this.taocanListData.travelId = id;
      this.gettravelSpecsList();
    },
    handleSizeChange1(num) {
      this.roomListData.pageSize = num;
      this.getRoomList();
    },
    handleCurrentChange1(num) {
      this.roomListData.currentPage = num;
      this.getRoomList();
    },
    handleSizeChange2(num) {
      this.ticketListData.pageSize = num;
      this.getTicketList();
    },
    handleCurrentChange2(num) {
      this.ticketListData.currentPage = num;
      this.getTicketList();
    },
    handleSizeChange3(num) {
      this.lineListData.pageSize = num;
      this.getlineList();
    },
    handleCurrentChange3(num) {
      this.lineListData.currentPage = num;
      this.getlineList();
    },
    handleSizeChange4(num) {
      this.taocanListData.pageSize = num;
      this.gettravelSpecsList();
    },
    handleCurrentChange4(num) {
      this.taocanListData.currentPage = num;
      this.gettravelSpecsList();
    },
  },
};
</script>
<style lang="less" scoped>
.selectProducts {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    span {
      margin: 0 20px;
    }
    .el-input {
      width: 200px;
    }
    .el-select {
      margin: 0 20px;
    }
  }
  .line {
    .el-select {
      width: 150px;
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
  .pb_footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    .el-button {
      margin-right: 20px;
    }
  }
}
</style>